import { useState } from 'react';
import { Typography, Button, Stack, Switch, Tooltip } from '@mui/material';
import Modal from './Modal';
import { NoteAPI } from 'api/notes';
import { useParams } from 'react-router-dom';
import ButtonWithAlert from './ButtonWithAlert';

function PublishPrompt({
    open,
    onClose,
    onPublish,
    publishNote,
    note,
    publish,
    conversation,
}) {
    const { customerId } = useParams();

    return (
        <Modal open={open} onClose={onClose}>
            <Stack spacing={2} sx={{ width: 300 }}>
                <Typography>
                    Are you sure you want to {publish ? 'publish' : 'unpublish'}{' '}
                    this note to all members? This cannot be undone!
                </Typography>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{ width: '100%', display: 'flex' }}
                >
                    <Button
                        variant="contained"
                        sx={{ flex: 1 }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <ButtonWithAlert
                        variant="contained"
                        color="error"
                        sx={{ flex: 1 }}
                        apiCall={NoteAPI.updateNote}
                        apiArgs={{
                            note,
                            publish,
                            conversation,
                            customerId,
                        }}
                        onSuccess={(res) => {
                            setTimeout(() => {
                                const publishedNote = {
                                    ...note,
                                    ...res.data,
                                };
                                publishNote('PUBLISH', [publishedNote]);
                                onPublish();
                                onClose();
                            }, 500);
                        }}
                        successMessage="The note has been published"
                    >
                        {publish ? 'Publish' : 'Unpublish'}
                    </ButtonWithAlert>
                </Stack>
            </Stack>
        </Modal>
    );
}

export default function PublishSwitch({
    note,
    conversation,
    onPublish,
    publishNote,
    ...props
}) {
    const [open, setOpen] = useState(false);
    const [publish, setPublish] = useState(note.publish);

    const onClick = (event) => {
        event.stopPropagation();
        setPublish(event.target.checked);
        setOpen(true);
    };

    const onClose = (event) => {
        if (event) event.stopPropagation();
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Publish Note">
                <Switch checked={note.publish} onChange={onClick} {...props} />
            </Tooltip>
            {open && (
                <PublishPrompt
                    open={open}
                    onClose={onClose}
                    note={note}
                    publish={publish}
                    conversation={conversation}
                    onPublish={onPublish}
                    publishNote={publishNote}
                />
            )}
        </>
    );
}
