import React from 'react';
import { useParams } from 'react-router-dom';
import ButtonWithAlert from 'components/views/ButtonWithAlert';

import { NoteAPI } from 'api/notes';

export default function AddNoteButton({
    text,
    conversation,
    disabled,
    onAdd,
    ...props
}) {
    const { customerId } = useParams();

    return (
        <ButtonWithAlert
            variant="contained"
            disabled={disabled}
            apiCall={NoteAPI.createNote}
            apiArgs={{
                text,
                conversation,
                customerId,
            }}
            onSuccess={(res) => {
                onAdd();
            }}
            successMessage="The Note has been created"
            {...props}
        >
            Add
        </ButtonWithAlert>
    );
}
