import React from 'react';
import { useParams } from 'react-router-dom';
import ButtonWithAlert from 'components/views/ButtonWithAlert';

import { NoteAPI } from 'api/notes';

export default function SaveNoteButton({
    note,
    text,
    publish = false,
    conversation,
    disabled,
    onSave,
    saveNote,
    ...props
}) {
    const { customerId } = useParams();

    return (
        <ButtonWithAlert
            variant="contained"
            disabled={disabled}
            apiCall={NoteAPI.updateNote}
            apiArgs={{
                note,
                text,
                publish: note.publish,
                conversation,
                customerId,
            }}
            onSuccess={(res) => {
                const modifiedNote = {
                    ...note,
                    ...res.data,
                };
                saveNote('UPDATE', [modifiedNote]);
                onSave();
                console.log(res);
            }}
            successMessage="The Note has been saved"
            {...props}
        >
            Save
        </ButtonWithAlert>
    );
}
