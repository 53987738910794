import moment from 'moment';

export default class Date {
    static dateFormat(string) {
        return moment(string).format('MM/DD/YYYY');
    }
    static dateTimeFormat(string) {
        return moment(string).format('MM/DD/YYYY hh:mm:ss');
    }
}
