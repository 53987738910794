import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default class Draft {
    static createEmptyEditorState() {
        return EditorState.createEmpty();
    }
    static textToEditorState(text) {
        let blockArray = htmlToDraft(text);
        let contentState = ContentState.createFromBlockArray(
            blockArray.contentBlocks,
            blockArray.entityMap
        );
        return EditorState.createWithContent(contentState);
    }
    static editorStateToText(editorState) {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }
}
