import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';

export default function NoteButton({ openNoteDrawer, openInsightDrawer }) {
    const [open, setOpen] = openNoteDrawer;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <Tooltip title="Take Note">
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                    mr: 2,
                    ...((open || openInsightDrawer) && { display: 'none' }),
                }}
            >
                <NotesIcon />
            </IconButton>
        </Tooltip>
    );
}
