import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import { yellow, red, grey } from '@mui/material/colors';
import {
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Drawer,
    Button,
    Stack,
    Typography,
    Divider,
    Avatar,
    CircularProgress,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Editor } from 'react-draft-wysiwyg';

import AddNoteButton from 'components/views/buttons/AddNoteButton';
import EditNoteButton from 'components/views/buttons/EditNoteButton';
import DeleteNoteButton from 'components/views/buttons/DeleteNoteButton';
import SaveNoteButton from 'components/views/buttons/SaveNoteButton';
import PublishSwitch from 'components/views/PublishSwitch';

import useIsAdmin from 'hooks/useIsAdmin';

import Draft from 'utils/drafts';
import Date from 'utils/dates';

import { NoteAPI } from 'api/notes';
import SocketConnection from 'api/webSocketConnection';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const NoteList = ({
    notes,
    conversation,
    getAllNotes,
    onMessageSent,
    onEditNote,
    ...props
}) => {
    const [isAdmin, isLoading] = useIsAdmin();

    const PublishByAdmin = () => {
        return (
            <CardHeader
                avatar={
                    <Avatar
                        sx={{
                            width: 18,
                            height: 18,
                            bgcolor: red[500],
                            fontSize: 15,
                        }}
                        aria-label="recipe"
                    >
                        A
                    </Avatar>
                }
                title={
                    <>
                        Published by <b>@admin</b>
                    </>
                }
                sx={{ bgcolor: grey['A100'] }}
            />
        );
    };

    return (
        <Stack spacing={0}>
            {notes.map((note, index) => (
                <Card
                    variant="outlined"
                    key={index}
                    sx={{
                        minWidth: 205,
                        margin: '10px',
                        borderRadius: '20px',
                        [`&:hover`]: {
                            boxShadow:
                                '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)',
                            border: '1px solid #fa1b1b',
                        },
                    }}
                    {...props}
                >
                    {note.publish ? <PublishByAdmin /> : <></>}
                    <CardContent>
                        <div
                            dangerouslySetInnerHTML={{ __html: note.text }}
                        ></div>
                    </CardContent>
                    <CardActions
                        sx={{ justifyContent: 'space-between', padding: '5px' }}
                    >
                        <div>
                            {!isAdmin && note.publish ? (
                                <></>
                            ) : (
                                <>
                                    <EditNoteButton
                                        note={note}
                                        onEdit={() => {
                                            onEditNote(note);
                                        }}
                                    />
                                    <DeleteNoteButton
                                        note={note}
                                        deleteNote={onMessageSent}
                                    />
                                    {isAdmin && (
                                        <PublishSwitch
                                            note={note}
                                            conversation={conversation}
                                            onPublish={() => {
                                                getAllNotes();
                                            }}
                                            publishNote={onMessageSent}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        {Date.dateFormat(note.last_updated)}
                    </CardActions>
                </Card>
            ))}
        </Stack>
    );
};

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function NoteView({
    openNoteDrawer,
    conversationId,
    sx,
    ...restProps
}) {
    const theme = useTheme();
    const { customerId } = useParams();
    const drawerElement = useRef();
    const [editorState, setEditorState] = useState(
        Draft.createEmptyEditorState()
    );
    const [noteList, setNoteList] = useState([]);
    const falseEditingValue = {
        state: false,
        currentNote: {
            id: undefined,
            text: Draft.editorStateToText(editorState),
        },
    };
    const [editing, setEditing] = useState(falseEditingValue);
    const [open, setOpen] = openNoteDrawer;

    const editorDisabled =
        Draft.editorStateToText(editorState) ===
        Draft.editorStateToText(Draft.createEmptyEditorState());
    const [noteLoading, setNoteLoading] = useState(false);

    const [wsConnection, setWSConnection] = useState(new SocketConnection());

    const toolbarConfig = {
        options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'image',
            'history',
        ],
        inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
            inDropdown: true,
        },
        textAlign: {
            inDropdown: true,
        },
        link: {
            visible: true,
            inDropdown: false,
            addLink: { visible: true },
            removeLink: { visible: true },
        },
        image: {
            visible: true,
            fileUpload: true,
            url: true,
        },
        history: {
            visible: true,
            inDropdown: false,
            undo: { visible: true },
            redo: { visible: true },
        },
    };

    const getAllNotes = () => {
        NoteAPI.getAllNote({
            conversationId,
            customerId,
            onSuccess: (res) => {
                setNoteList(res.data.results);
                setNoteLoading(false);
            },
            onFailure: (message) => {
                console.log(message);
                setNoteLoading(false);
            },
        });
    };

    const onEditNote = (note) => {
        setEditing({
            state: true,
            currentNote: note,
        });

        // Scroll to top
        if (drawerElement) {
            drawerElement.current.firstElementChild.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        setEditorState(Draft.textToEditorState(note.text));
    };

    const onSaveNote = () => {
        setEditing(falseEditingValue);
        setEditorState(Draft.createEmptyEditorState());
    };

    const onCancelNote = () => {
        setEditing(falseEditingValue);
        setEditorState(Draft.createEmptyEditorState());
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const modifyNote = (modifiedNote) => {
        const newNotes = [...noteList];

        const index = newNotes.map((cl) => cl.id).indexOf(modifiedNote.id);
        if (index !== -1) {
            newNotes[index] = modifiedNote;
        }

        setNoteList(newNotes);
    };

    const publishNote = (publishedNote) => {
        const newNotes = [...noteList];

        if (publishedNote.publish) {
            newNotes.unshift(publishedNote);
        } else {
            const index = newNotes.map((cl) => cl.id).indexOf(publishedNote.id);
            if (index !== -1) {
                newNotes.splice(index, 1);
            }
        }

        setNoteList(newNotes);
    };

    const deleteNote = (note) => {
        const newNotes = [...noteList];

        const index = newNotes.map((cl) => cl.id).indexOf(note.id);
        if (index !== -1) {
            newNotes.splice(index, 1);
        }

        console.log('delete');
        setNoteList(newNotes);
    };

    const onMessageReceived = (bundle) => {
        const [type, data] = bundle;
        switch (type) {
            case 'PUBLISH':
                const [publishedNote] = data;
                publishNote(publishedNote);
                break;
            case 'UPDATE':
                const [modifiedNote] = data;
                modifyNote(modifiedNote);
                break;
            case 'DELETE':
                const [note] = data;
                deleteNote(note);
                break;
        }
    };

    const onMessageSent = (type, data) => {
        const bundle = [type, data];
        wsConnection.sendMessage(bundle);
    };

    wsConnection.setMessageReceivedCallback(onMessageReceived);

    useEffect(() => {
        wsConnection.connect('conversation', conversationId + 1000);
        setNoteLoading(true);
        getAllNotes();

        return () => {
            wsConnection.close();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Drawer
            sx={{ ...sx, display: 'block' }}
            variant="persistent"
            anchor="left"
            open={open}
            ref={drawerElement}
            {...restProps}
        >
            <DrawerHeader sx={{ justifyContent: 'space-between' }}>
                <LightbulbIcon sx={{ color: yellow.A700 }} />
                <Typography variant="h6">Keep Notes</Typography>
                <Stack flex={'1 0 auto'}></Stack>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <Card
                variant="outlined"
                sx={{
                    minWidth: 205,
                    margin: '10px',
                    borderRadius: '20px',
                    overflow: 'unset',
                }}
            >
                <CardContent>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbar={toolbarConfig}
                    />
                    {editing.state ? (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <SaveNoteButton
                                note={editing.currentNote}
                                disabled={editorDisabled}
                                text={Draft.editorStateToText(editorState)}
                                conversation={conversationId}
                                onSave={onSaveNote}
                                saveNote={onMessageSent}
                            />
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={onCancelNote}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    ) : (
                        <AddNoteButton
                            disabled={editorDisabled}
                            text={Draft.editorStateToText(editorState)}
                            conversation={conversationId}
                            onAdd={() => {
                                getAllNotes();
                                setEditorState(Draft.createEmptyEditorState());
                            }}
                        />
                    )}
                </CardContent>
            </Card>
            {noteLoading ? (
                <CircularProgress />
            ) : (
                <NoteList
                    notes={noteList}
                    conversation={conversationId}
                    getAllNotes={getAllNotes}
                    onMessageSent={onMessageSent}
                    onEditNote={onEditNote}
                />
            )}
        </Drawer>
    );
}
