import BaseAPI from 'api/baseAPI';

export class NoteAPI {
    static createNote({
        text,
        conversation,
        customerId,
        onSuccess = () => {
            return null;
        },
        onFailure = () => {
            return null;
        },
    }) {
        const note = { text, conversation };
        BaseAPI.call({
            type: 'create',
            path: 'conversations/notes/',
            object: note,
            customerId,
            onSuccess,
            onFailure,
        });
    }

    static updateNote({
        note,
        text,
        publish = false,
        conversation,
        customerId,
        onSuccess = () => {
            return null;
        },
        onFailure = () => {
            return null;
        },
    }) {
        const noteParams = { text, publish, conversation };
        BaseAPI.call({
            type: 'patch',
            path: `conversations/notes/${note.id}/`,
            object: noteParams,
            customerId,
            onSuccess,
            onFailure,
        });
    }

    static deleteNote({
        note,
        customerId,
        onSuccess = () => {
            return null;
        },
        onFailure = () => {
            return null;
        },
    }) {
        BaseAPI.call({
            type: 'delete',
            path: `conversations/notes/${note.id}/`,
            object: null,
            customerId,
            onSuccess,
            onFailure,
        });
    }

    static getNote({
        noteId,
        customerId,
        onSuccess = () => {
            return null;
        },
        onFailure = () => {
            return null;
        },
    }) {
        BaseAPI.call({
            type: 'get',
            path: `conversations/notes/${noteId}`,
            customerId,
            onSuccess,
            onFailure,
        });
    }

    static getAllNote({
        // searchText,
        // page,
        conversationId,
        customerId,
        onSuccess = () => {
            return null;
        },
        onFailure = () => {
            return null;
        },
    }) {
        BaseAPI.call({
            type: 'get',
            path: `conversations/notes/?conversationId=${conversationId}`,
            // object: { searchText },
            customerId,
            onSuccess,
            onFailure,
        });
    }

    static getStats({
        conversationId,
        customerId,
        onSuccess = () => {
            return null;
        },
        onFailure = () => {
            return null;
        },
    }) {
        BaseAPI.call({
            type: 'get',
            path: `stats/conversations/${conversationId}`,
            customerId,
            onSuccess,
            onFailure,
        });
    }
}
