import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';

export default function EditNoteButton({ note, onEdit, ...props }) {
    return (
        <>
            <Tooltip title="Edit Note">
                <IconButton {...props} onClick={onEdit}>
                    <BorderColorIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </>
    );
}
